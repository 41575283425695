import '@babel/polyfill';
import axios from "axios";
const resultForm = document.getElementById('search_form');
const resultFormMobile = document.getElementById('search_form-mobile');

if(resultForm){
    resultForm.addEventListener('submit', e => {
      e.preventDefault();
      const name = document.getElementById('desktop_search').value;
      searchPost(name);
      //console.log('hey')
    });
}
if(resultFormMobile){
    resultForm.addEventListener('submit', e => {
      e.preventDefault();
      const name = document.getElementById('mobile_search').value;
      searchPost(name);
      //console.log('hey')
    });
}
const searchPost = async (name) => {
    try{
       const res = await axios({
           method: 'GET',
           url: `/search/word/${name}`,
           });
           location.assign(`/search/word/${name}`);
       } catch (err) {
        console.log(err.response.data.message);
       }
};